





































import http from '@/utils/http'
import { Component, Vue } from 'vue-property-decorator'
import SubjectSelect from '@/components/SubjectSelect.vue'

@Component({ components: { SubjectSelect } })
export default class extends Vue {
  form = {
    name: '',
    mobile: '',
    sex: '',
    idCard: '',
    subjectId: null,
  }
  loading = false

  rules = {
    name: [
      { required: true, message: '请输入姓名', trigger: 'blur' },
      { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' },
    ],
    mobile: [
      { required: true, message: '请输入手机号', trigger: 'change' },
      { min: 11, max: 11, message: '长度11个字符', trigger: 'change' },
    ],
    sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
    idCard: [
      { required: true, message: '请输入身份证号', trigger: 'change' },
      { min: 18, max: 18, message: '长度18个字符', trigger: 'change' },
    ],
    subjectId: [{ required: true, message: '请选择报名科目', trigger: 'change' }],
  }

  trySubmit() {
    const form = this.$refs['form'] as any
    form.validate((valid: boolean) => {
      if (valid) {
        this.onSubmit()
      } else {
        return false
      }
    })
  }

  onSubmit() {
    this.loading = true
    http
      .post('/pub/register', this.form)
      .then(() => {
        this.$message.success('报名成功')
        this.resetForm()
      })
      .catch((e: any) => {
        const msg = (e.response && e.response.data && e.response.data.message) || '报名失败'
        this.$message.error(msg)
      })
      .finally(() => {
        this.loading = false
      })
  }

  resetForm() {
    this.form = {
      name: '',
      mobile: '',
      sex: '',
      idCard: '',
      subjectId: null,
    }
  }
}
