<template>
  <el-select
    :value="value"
    clearable
    placeholder="--请选择--"
    filterable
    :loading="loading"
    @change="change"
    @input="input"
    @clear="clear"
  >
    <el-option v-for="item in options" :key="item.value" :value="item.value" :label="item.label"></el-option>
  </el-select>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'SubjectSelect',
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      options: [],
      loading: false,
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      http.get('/pub/subject')
        .then((rsp) => {
          this.options = rsp.data.map((item) => {
            return { label: `${item.name}`, value: item.id }
          })
        })
        .finally(() => (this.loading = false))
    },
    change(val) {
      this.$emit('change', val)
    },
    input(val) {
      this.$emit('input', val)
    },
    clear() {
      this.$emit('input', null)
    },
  },
}
</script>
